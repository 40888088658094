<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg6 md6 sm12>
                    <v-select label="Loại hàng" v-model="feeWeight.ProductType" :items="product_type_list" item-text="name" item-value="value"></v-select>
                </v-flex>
                <v-flex lg6 md6 sm12>
                    <v-select label="Kho VN" v-model="feeWeight.ReceivePlaceID" :items="stores" item-text="Name" item-value="ID"></v-select>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="feeWeight.WeightFrom" :decimal="1" label="Cân nặng (Min)" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="feeWeight.WeightTo" :decimal="1" label="Cân nặng (Max)" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="feeWeight.Amount" :decimal="0" label="Tiền phí" class="input-group--focused"></h-currency-input>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            loading: state => state.feeWeight.loading,
            feeWeight: state => state.feeWeight.selected,
            stores: state => state.commons.stores,
            configuration: state => state.configuration.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        },
        product_type_list() {
            return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
        },
    },
    watch: {
        modifyId() {
            this.refreshData();
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("feeWeight/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("feeWeight/setDetail", { data: {
                        ID: 0,
                        ChinaStoreID: 1,
                        ReceivePlaceID: 1,
                        WeightFrom: 0,
                        WeightTo: 0,
                        Amount: 0
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("feeWeight/setDetail", {
                data: {
                    ...this.feeWeight,
                    ModifiedBy: this.$store.state.authentication.user.username
                },
                id: this.feeWeight.ID
            });
            // window.getApp.$emit("APP_NAVIGATE", "FeeWeightList");
        },
    },
    mounted() {
        this.refreshData();
        this.$store.dispatch("configuration/getDetail", 1);
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.feeWeight.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
